import { collection, db, setDoc, doc, query } from "../firebase";
import { useState, useEffect } from "react";
import { getDocs, onSnapshot } from "@firebase/firestore";

const useOrders = () => {
  const [orders, setOrders] = useState([]);

  const ordersRef = collection(db, "orders");

  const q = query(ordersRef);

  const fetchOrders = async () => {
    const response = await getDocs(q);
    const data = response.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setOrders(data);
  };

  useEffect(() => {
    const unscribe = onSnapshot(q, () => fetchOrders());
    return unscribe;
  }, []);

  const acceptOrder = async (id) => {
    updateOrderStatus("EN_PROCESO", id);
  };

  const declineOrder = async (id, iduser) => {
    const user = doc(db, "users", iduser);
    updateOrderStatus("RECHAZADO", id);
    setDoc(
      user,
      {
        pedidoActivo: false,
      },
      { merge: true }
    );
  };

  const readyForPickup = async (id, iduser) => {
    const user = doc(db, "users", iduser);
    await updateOrderStatus("COMPLETADO", id);
    setDoc(
      user,
      {
        pedidoActivo: false,
      },
      { merge: true }
    );
  };

  const onDelivery = async (id) => {
    updateOrderStatus("EN_CAMINO", id);
  };

  const updateOrderStatus = async (newStatus, id) => {
    const newOrder = doc(db, "orders", id);
    setDoc(
      newOrder,
      {
        status: newStatus,
      },
      { merge: true }
    );
  };

  return {
    orders,
    fetchOrders,
    onDelivery,
    acceptOrder,
    declineOrder,
    readyForPickup,
  };
};

export default useOrders;
