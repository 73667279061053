import { message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, db, doc, getDocs, query, setDoc } from "../firebase";

const useDishes = () => {
    const navigation = useNavigate()
    const [dishes, setDishes] = useState([]);

    const fetchDishes = async (restaurantId) => {
        const dishesRef = collection(db, "dishes");
        const q = query(dishesRef);

        const response = await getDocs(q);
        const data = response.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        setDishes(data);
    };

    const deleteDish = (dbDish) => {
        const dish = doc(db, "dishes", dbDish.id);
        setDoc(
            dish,
            {
                ...dbDish,
                deleted: true,
            },
            { merge: true }
        ).then(() => {
            message.success("Platillo creado exitosamente");
            navigation("/menu");
        });
    }

    const desactivarDish = async (dish) => {
        const newDish = doc(db, "dishes", dish.id);

        setDoc(
            newDish,
            {
                desactivado: "true"
            },
            { merge: true }
        )

        await new Promise((resolve) => setTimeout(resolve, 1000));
        window.location.reload();
    };

    const activarDish = async (dish) => {
        const newDish = doc(db, "dishes", dish.id);

        setDoc(
            newDish,
            {
                desactivado: "false"
            },
            { merge: true }
        )

        await new Promise((resolve) => setTimeout(resolve, 1000));
        window.location.reload();
    };

    useEffect(() => {
        fetchDishes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        dishes,
        fetchDishes,
        deleteDish,
        desactivarDish,
        activarDish
    };
};

export default useDishes;
