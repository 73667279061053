import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, db, doc, getDocs, query, setDoc } from "../firebase";

const useRestaurant = () => {
    const navigation = useNavigate()
    const [restaurant, setRestaurant] = useState([]);

    const fetchDishes = async () => {
        const restaurantRef = collection(db, "restaurant");
        const q = query(restaurantRef);

        const response = await getDocs(q);
        const data = response.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        setRestaurant(data);
    };


    const updatePorcentage = async (descount, id) => {
        const newRestaurant = doc(db, "restaurant", id);

        setDoc(
            newRestaurant,
            {
                descuento: parseInt(descount)
            },
            { merge: true }
        )

        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigation('/menu')
    };



    useEffect(() => {
        fetchDishes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        restaurant,
        updatePorcentage
    };
};

export default useRestaurant;
