import AppRoutes from "./components/AppRoutes";
import RestaurantContextProvider from "./contexts/RestaurantContext";

const App = () => {
  return (

    <RestaurantContextProvider>
      <AppRoutes />
    </RestaurantContextProvider>

  );
};

export default App;
