import { Card, Table, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useOrders from "../../hooks/useOrders";

const OrderHistory = () => {
  const { orders } = useOrders();

  const navigate = useNavigate();

  const renderOrderStatus = (status) => {
    const statusToColor = {
      NUEVO: "blue",
      EN_PROCESO: "orange",
      EN_CAMINO: "green",
      COMPLETADO: "green",
      RECHAZADO: "red",
    };
    return <Tag color={statusToColor[status]}>{status}</Tag>;
  };

  const tableColumns = [
    {
      title: "Pedido",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Creado",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("DD/MM/YYYY"),
    },
    {
      title: "Precio",
      dataIndex: "total",
      key: "total",
      render: (price) => `${price?.toFixed(2) ?? 0} $`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderOrderStatus,
    },
  ];
  return (
    <Card title={"Historial de pedidos"} style={{ margin: 20 }}>
      <Table
        dataSource={orders}
        columns={tableColumns}
        rowKey="id"
        onRow={(orderItem) => ({
          onClick: () => navigate(`/order/${orderItem.id}`),
        })}
      />
    </Card>
  );
};

export default OrderHistory;
