import { Card, Descriptions, Divider, List, Button, Tag } from "antd";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, db, query, where, getDocs } from "../../firebase";
import useOrders from "../../hooks/useOrders";
import { onSnapshot } from "firebase/firestore";

const statusToColor = {
  NUEVO: "blue",
  EN_PROCESO: "orange",
  EN_CAMINO: "green",
  COMPLETADO: "green",
  RECHAZADO: "red",
};

const DetailedOrder = () => {
  const { onDelivery, acceptOrder, declineOrder, readyForPickup } = useOrders();
  const { id } = useParams();
  const [state, setState] = useState([]);
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    const unscribe = onSnapshot(collection(db, "orders"), (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const order = data.find((order) => order.id === id);
      setState(order);
    });
    return unscribe;
  }, []);

  const getUser = async () => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("sub", "==", state?.userID));
    console.log(state?.userID);

    const response = await getDocs(q);
    const data = response.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCustomer(data[0]);
  };

  useEffect(() => {
    getUser();
  }, [state]);

  console.log(customer);

  return (
    <Card title={`Order ${id}`} style={{ margin: 20 }}>
      <Tag style={{ marginBottom: 10 }} color={statusToColor[state?.status]}>
        {state?.status}
      </Tag>
      <Descriptions bordered column={{ lg: 1, md: 1, sm: 1 }}>
        <Descriptions.Item label="Nombre">{customer?.name}</Descriptions.Item>
        <Descriptions.Item label="Apellido">
          {customer?.lastname}
        </Descriptions.Item>
        <Descriptions.Item label="Palco">{customer?.palco}</Descriptions.Item>
        <Descriptions.Item label="Metodo de pago">
          {state?.tipoPago}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <List
        dataSource={state.dishes}
        renderItem={(dishItem) => (
          console.log(dishItem),
          (
            <List.Item>
              <div style={{ fontWeight: "bold" }}>
                {dishItem?.Dish?.name} - {dishItem?.comentario}
              </div>
              <div>
                $ {dishItem?.Dish?.price} x {dishItem?.quantity}
              </div>
            </List.Item>
          )
        )}
      />
      <Divider />
      <div style={styles.totalSumContainer}>
        <h2>Total:</h2>
        <h2 style={styles.totalPrice}>${state?.total?.toFixed(2)}</h2>
      </div>
      <Divider />
      {state?.status === "NUEVO" && (
        <div style={styles.buttonsContainer}>
          <Button
            block
            type="danger"
            size="large"
            style={(styles.button, { backgroundColor: "red", color: "white" })}
            onClick={() => declineOrder(id, customer.id)}
          >
            Cancelar Order
          </Button>
          <Button
            block
            type="primary"
            size="large"
            style={
              (styles.button, { backgroundColor: "green", color: "white" })
            }
            onClick={() => acceptOrder(id)}
          >
            Aceptar Order
          </Button>
        </div>
      )}
      {state.status === "EN_PROCESO" && (
        <Button
          block
          type="primary"
          size="large"
          style={(styles.button, { backgroundColor: "green", color: "white" })}
          onClick={() => onDelivery(id)}
        >
          Proceder a entregar
        </Button>
      )}
      {state.status === "EN_CAMINO" && (
        <Button
          block
          type="primary"
          size="large"
          style={(styles.button, { backgroundColor: "green", color: "white" })}
          onClick={() => readyForPickup(id, customer.sub)}
        >
          Proceder a completar
        </Button>
      )}
    </Card>
  );
};

const styles = {
  totalSumContainer: {
    flexDirection: "row",
    display: "flex",
  },
  totalPrice: {
    marginLeft: "auto",
    fontWeight: "bold",
  },
  buttonsContainer: {
    display: "flex",
    paddingBottom: 30,
  },
  button: {
    marginRight: 20,
    marginLeft: 20,
  },
};

export default DetailedOrder;
