import { createContext, useState, useEffect, useContext } from "react";
import { auth } from '../firebase'

const RestaurantContext = createContext({});

const RestaurantContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [sub, setSub] = useState(null);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const users = await auth.currentUser;
      setUser(users);
      setSub(user?.uid);
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   // Create listener that will stop observing the model once the sync process is done
  //   const removeListener = Hub.listen("datastore", async (capsule) => {
  //     const {
  //       payload: { event, data },
  //     } = capsule;

  //     console.log("DataStore event", event, data);

  //     if (event === "ready") {
  //       const restaurants = await DataStore.query(Restaurant);

  //       setRestaurant(restaurants[0]);
  //     }
  //   });

  //   // Start the DataStore, this kicks-off the sync process.
  //   DataStore.start();

  //   return () => {
  //     removeListener();
  //   };
  // }, []);

  return (
    <RestaurantContext.Provider value={{ sub }}>
      {children}
    </RestaurantContext.Provider>
  );
};

export default RestaurantContextProvider;

export const useRestaurantContext = () => useContext(RestaurantContext);
