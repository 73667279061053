import { Navigate, Outlet } from "react-router-dom";
import SideMenu from "../SideMenu/index";
import { Layout, Image } from "antd";
import Logo from "../../assets/images/logo.jpg";



const PrivateRoute = () => {
    const user = localStorage.getItem("email");
    const { Sider, Content } = Layout;

    return (
        <>
            {user ? (
                <Layout>
                    <Sider style={{ height: "100vh", backgroundColor: "white" }}>
                        <Image src={Logo} />
                        <SideMenu />
                    </Sider>
                    <Layout>
                        <Content>
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
            ) : (
                <Navigate to="/login" />
            )}
        </>
    );
};

export default PrivateRoute;
