import {
  Card,
  Form,
  Input,
  Select,
  InputNumber,
  Button,
  message,
} from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { db, doc, setDoc } from '../../firebase'


const { TextArea } = Input;
const { Option } = Select;

const EditMenuItem = () => {
  const categorias = [
    "Entradas",
    "Alitas/Boneless",
    "Nachos/Fries",
    "Hotdogs",
    "Sabritas",
    "Vino",
    "Licor",
    "Cortes",
    "Mariscos",
    "Hamburguesas",
    "Pizzas",
    "Pastas",
    "Sopas",
    "Tacos",
    "Ensaladas",
    "Postres",
    "Guarniciones",
    "Mixologia",
  ];
  const navigation = useNavigate()
  const location = useLocation();
  const [categoria, setCategoria] = useState(location.state.dishItem.categoria);


  const onChange = (value) => {
    setCategoria(value);
  };

  const onSearch = (value) => {
    setCategoria(value);
  };

  const onFinish = async ({ name, description, price }) => {

    const newDish = doc(db, "dishes", location.state.dishItem.id);

    setDoc(
      newDish,
      {
        name,
        description,
        price,
        categoria: categoria,
      },
      { merge: true }
    ).then(() => {
      message.success("Platillo actualizado exitosamente");
      setTimeout(() => {
        navigation("/menu")
      }, 3000);
    });

  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };



  return (
    <Card title="Editar Platillo" style={{ margin: 20 }}>
      <Form
        layout="vertical"
        wrapperCol={{ span: 8 }}
        onFinish={onFinish}
        initialValues={{
          name: location.state.dishItem.name,
          description: location.state.dishItem.description,
          price: location.state.dishItem.price,
          category: location.state.dishItem.categoria,
        }}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Nombre del platillo"
          name="name"
          rules={[{ required: true }]}
          required
        >
          <Input placeholder="Ingresa el nombre del platillo" />
        </Form.Item>
        <Form.Item
          label="Descripción del platillo"
          name="description"
          rules={[{ required: true }]}
          required
        >
          <TextArea
            rows={4}
            placeholder="Ingresa la descripcion del platillo"
          />
        </Form.Item>
        <Form.Item label="Categoria del platillo">
          <Select
            showSearch
            placeholder="Selecciona el tipo de platillo"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            defaultValue={location.state.dishItem.categoria}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          //select the category of the dish
          // value={categoria}
          >
            {categorias.map((categoria) => (
              <Option key={categoria} value={categoria}>
                {categoria}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* <input accept="image/*" type="file" onChange={normFile} />
        <label htmlFor="button-add-picture"></label> */}
        <Form.Item
          label="Precio ($)"
          name="price"
          rules={[{ required: true }]}
          required
          style={{ marginTop: 20 }}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit">
            Editar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default EditMenuItem;
