import {
  Form,
  Input,
  Button,
  Card,
  InputNumber,
  message,
  Upload,
  Select,
} from "antd";
import { DataStore } from "aws-amplify";
import { useRestaurantContext } from "../../contexts/RestaurantContext";
import { useNavigate } from "react-router-dom";
import { InboxOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Storage } from "aws-amplify";
import { useId } from "react";
import { collection, db, doc, getDownloadURL, ref, setDoc, storage, uploadBytesResumable } from '../../firebase'
const { TextArea } = Input;

const { Option } = Select;

const CreateMenuItem = () => {
  const id = useId();
  const [file, setFile] = useState({
    file: null,
    fileName: null,
  });
  const [categoria, setCategoria] = useState("");
  const categorias = [
    "Entradas",
    "Alitas/Boneless",
    "Nachos/Fries",
    "Hotdogs",
    "Sabritas",
    "Vino",
    "Licor",
    "Cortes",
    "Mariscos",
    "Hamburguesas",
    "Pizzas",
    "Pastas",
    "Sopas",
    "Tacos",
    "Ensaladas",
    "Postres",
    "Guarniciones",
    "Mixologia",
  ];

  const { sub } = useRestaurantContext();
  const navigation = useNavigate();

  const pushImgToS3 = async (uri, filename) => {
    if (uri === null) return;
    setFile({ file: uri, fileName: filename });
  };

  const normFile = async (e) => {
    if (!e || !e.target || !e.target.files) return;

    const filesLength = e.target.files.length;
    // Loop through all selected files
    for (let i = 0; i < filesLength; i++) {
      const file = e.target.files[i];
      const filename = file.name
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
      const fileExtension = file.name.split(".").pop();
      // Define the image name
      let mainImgName = filename + "-" + id + "." + fileExtension;
      // Push the image to S3
      await pushImgToS3(file, mainImgName);
    }
  };

  const onChange = (value) => {
    setCategoria(value);
  };

  const onSearch = (value) => {
    setCategoria(value);
  };

  const onFinish = async ({ name, description, price }) => {

    const newDish = doc(collection(db, "dishes"));
    const storageRef = ref(storage, `dishes/${name}`);
    await uploadBytesResumable(storageRef, file.file);
    const imageUrl = await getDownloadURL(storageRef);


    setDoc(
      newDish,
      {
        name,
        description,
        price,
        image: imageUrl,
        categoria: categoria,
        desactivado: "false",
      },
      { merge: true }).then(() => {
        message.success("Platillo creado exitosamente");
        navigation("/menu");
      });

  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Card title="Nuevo Platillo" style={{ margin: 20 }}>
      <Form
        layout="vertical"
        wrapperCol={{ span: 8 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Nombre del platillo"
          name="name"
          rules={[{ required: true }]}
          required
        >
          <Input placeholder="Ingresa el nombre del platillo" />
        </Form.Item>
        <Form.Item
          label="Descripción del platillo"
          name="description"
          rules={[{ required: true }]}
          required
        >
          <TextArea
            rows={4}
            placeholder="Ingresa la descripcion del platillo"
          />
        </Form.Item>
        <Form.Item>
          <Select
            showSearch
            placeholder="Selecciona el tipo de platillo"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {categorias.map((categoria) => (
              <Option key={categoria} value={categoria}>
                {categoria}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <input accept="image/*" type="file" onChange={normFile} />
        <label htmlFor="button-add-picture">
          {/* <Button
            variant="contained"
            size="large"
            color="secondary"
            component="span"
          >
            Agregar
          </Button> */}
        </label>
        <Form.Item
          label="Precio ($)"
          name="price"
          rules={[{ required: true }]}
          required
          style={{ marginTop: 20 }}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Button  htmlType="submit">
            Agregar
          </Button>
        </Form.Item>
      </Form>
      {/* <input type="file" onChange={normFile} /> */}
    </Card>
  );
};

export default CreateMenuItem;
