import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";

const SideMenu = () => {
  const navigate = useNavigate();

  const onClick = async (menuItem) => {
    if (menuItem.key === "signOut") {
      await auth.signOut();
      localStorage.removeItem("email");
      localStorage.removeItem("uid");
      navigate("/login");
    } else {
      navigate(menuItem.key);
    }
  };

  const mainMenuItems = [
    {
      key: "/orders",
      label: "Ordenes",
    },
    {
      key: "menu",
      label: "Menu",
    },
    {
      key: "order-history",
      label: "Historial de Ordenes",
    },
    {
      key: "settings",
      label: "Ajustes",
    },
  ];

  const menuItems = [
    ...mainMenuItems,
    // {
    //   key: "settings",
    //   label: "Settings",
    // },
    {
      key: "signOut",
      label: "Cerrar Sesion",
      danger: "true",
    },
  ];

  return (
    <>
      {<h1 style={{ margin: 10 }}>{"Red and black room"}</h1>}
      <Menu items={menuItems} onClick={onClick} />
    </>
  );
};

export default SideMenu;
