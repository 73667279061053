import { Card, Table, Button, Popconfirm } from "antd";
import { Link, useNavigate } from "react-router-dom";
import useDishes from "../../hooks/useDishes";

const RestaurantMenu = () => {
  const navigate = useNavigate();
  const { dishes, deleteDish, activarDish, desactivarDish } = useDishes()


  const updateDish = (dish) => {
    navigate("/menu/edit", { state: { dishItem: dish } });
  };

  const tableColumns = [
    {
      title: "Platillo",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      render: (price) => `${price} $`,
    },
    {
      title: "Acciones",
      key: "action",
      render: (_, item) => (
        <>
          <Popconfirm
            placement="topLeft"
            title={"Estas seguro que deseas editar el platillo?"}
            onConfirm={() => updateDish(item)}
            okText="Yes"
            cancelText="No"
          >
            <Button style={{ marginLeft: 10, color: "black" }}>Editar</Button>
          </Popconfirm>
          <Popconfirm
            placement="topLeft"
            title={"Estas seguro de realizar esta acción?"}
            onConfirm={() =>
              item.desactivado === "true"
                ? activarDish(item)
                : desactivarDish(item)
            }
            okText="Yes"
            cancelText="No"
          >
            <Button style={{ marginLeft: 10 }}>
              {" "}
              {item.desactivado === "true" ? "Activar" : "Desactivar"}
            </Button>
          </Popconfirm>
          <Popconfirm
            placement="topLeft"
            title={"Estas seguro que deseas eliminar el platillo?"}
            onConfirm={() => deleteDish(item)}
            okText="Yes"
            cancelText="No"
          >
            <Button style={{ marginLeft: 10 }} danger>
              Eliminar
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const renderNewItemButton = () => (
    <Link to={"create"}>
      <Button >Nuevo Platillo</Button>
    </Link>
  );

  return (
    <Card title={"Menu"} style={{ margin: 20 }} extra={renderNewItemButton()}>
      <Table dataSource={dishes} columns={tableColumns} rowKey="id" />
    </Card>
  );
};

export default RestaurantMenu;
