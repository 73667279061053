import { Card, Table, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useOrders from "../../hooks/useOrders";

const Orders = () => {
  const { orders } = useOrders();

  const navigate = useNavigate();

  const renderOrderStatus = (status) => {
    console.log(status);
    const statusToColor = {
      [status === "NUEVO"]: "green",
      [status === "EN_PROCESO"]: "orange",
      [status === "EN_CAMINO"]: "blue",
    };
    return <Tag color={statusToColor[status]}>{status}</Tag>;
  };

  // const openRestaurant = async () => {
  // await DataStore.save(
  //   Restaurant.copyOf(restaurant, (updated) => {
  //     updated.abierto = "true";
  //   })
  // ).then((rest) => setRestaurant(rest));
  // setTimeout(() => {
  //   setButtonText("Cerrar restaurant");
  //   window.location.reload();
  // }, 4000);
  // };

  // const closeRestaurant = async () => {
  // await DataStore.save(
  //   Restaurant.copyOf(restaurant, (updated) => {
  //     updated.abierto = "false";
  //   })
  // ).then((rest) => setRestaurant(rest));
  // setTimeout(() => {
  //   setButtonText("Abrir restaurant");
  //   window.location.reload();
  // }, 4000);
  // };

  const tableColumns = [
    {
      title: "Pedido",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Creado",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("DD/MM/YYYY"),
    },
    {
      title: "Precio",
      dataIndex: "total",
      key: "total",
      render: (price) => `${price.toFixed(2)} $`,
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: renderOrderStatus,
    },
  ];

  return (
    <Card title={"Pedidos"} style={{ margin: 20 }}>
      {/* {restaurant && restaurant.abierto === "true" ? (
        <Button
          type="primary"
          style={{ marginBottom: 20 }}
          onClick={closeRestaurant}
        >
          Cerrar restaurant
        </Button>
      ) : (
        <Button
          type="primary"
          style={{ marginBottom: 20 }}
          onClick={openRestaurant}
        >
          Abrir restaurant
        </Button>
      )} */}
      <Table
        dataSource={orders.filter((item) => item.status === "NUEVO")}
        columns={tableColumns}
        rowKey="id"
        onRow={(orderItem) => ({
          onClick: () => navigate(`/order/${orderItem.id}`),
        })}
      />
    </Card>
  );
};

export default Orders;
