import DetailedOrder from "../../modules/DetailedOrder";
import Orders from "../../modules/Orders";
import RestaurantMenu from "../../modules/RestaurantMenu";
import CreateMenuItem from "../../modules/CreateMenuItem";
import OrderHistory from "../../modules/OrderHisotry";
import Settings from "../../modules/Settings";
import { Routes, Route } from "react-router-dom";
import EditMenuItem from "../../modules/EditMenu/EditMenuItem";
import PrivateRoute from "../AppRoutes/PrivateRoute";
import Login from '../../modules/AuthScreens/Login'
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<PrivateRoute />} >
        <Route path="/orders" element={<Orders />} />
        <Route path="order/:id" element={<DetailedOrder />} />
        <Route path="menu" element={<RestaurantMenu />} />
        <Route path="menu/create" element={<CreateMenuItem />} />
        <Route path="menu/edit" element={<EditMenuItem />} />
        <Route path="order-history" element={<OrderHistory />} />
        <Route path="settings" element={<Settings />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
