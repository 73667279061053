import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore, collection, addDoc, doc, setDoc, getDocs, query, where, deleteDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDqKSuhGhBogDpHuUbM4xBcsb6unzPIWX8",
    authDomain: "rnbr-9b011.firebaseapp.com",
    projectId: "rnbr-9b011",
    storageBucket: "rnbr-9b011.appspot.com",
    messagingSenderId: "23626687280",
    appId: "1:23626687280:web:ff96052364a3b2365edef5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
export {
    db,
    collection,
    doc,
    addDoc,
    setDoc,
    analytics,
    getDocs,
    storage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    auth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    query,
    where,
    deleteDoc
}