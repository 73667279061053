import React, { useState } from "react";
import { Form, Input, Card, Button } from "antd";
import useRestaurant from "../../hooks/useRestaurant";

const Settings = () => {
  const { restaurant, updatePorcentage } = useRestaurant()
  const [descuento, setDescuento] = useState(restaurant[0]?.descuento);
  console.log(restaurant)


  const onSubmit = async () => {
    updatePorcentage(descuento, restaurant[0]?.id)
    await new Promise((resolve) => setTimeout(resolve, 1000));
    window.location.reload();
  };



  return (
    <Card title="Detalles del restaurante" style={{ margin: 20 }}>
      <Form layout="vertical" wrapperCol={{ span: 8 }} onFinish={onSubmit}>
        <Form.Item label="Porcentaje de descuento">
          <Input
            placeholder="0 - 100"
            value={descuento}
            onChange={(e) => setDescuento(e.target.value)}
            type="number"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Settings;
